// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cm_ps";
export var homeWebDevProcess__between = "cm_pt";
export var homeWebDevProcess__contentFive = "cm_pD";
export var homeWebDevProcess__contentFour = "cm_pC";
export var homeWebDevProcess__contentOne = "cm_py";
export var homeWebDevProcess__contentThree = "cm_pB";
export var homeWebDevProcess__contentTwo = "cm_pz";
export var homeWebDevProcess__description = "cm_px";
export var homeWebDevProcess__image = "cm_pv";
export var homeWebDevProcess__title = "cm_pw";