// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fq_Ld";
export var achievementsSlider__badge_10 = "fq_Lp";
export var achievementsSlider__badge_11 = "fq_Lq";
export var achievementsSlider__badge_12 = "fq_Lr";
export var achievementsSlider__badge_13 = "fq_Ls";
export var achievementsSlider__badge_14 = "fq_Lt";
export var achievementsSlider__badge_15 = "fq_Lv";
export var achievementsSlider__badge_16 = "fq_Lw";
export var achievementsSlider__badge_17 = "fq_Lx";
export var achievementsSlider__badge_18 = "fq_Ly";
export var achievementsSlider__badge_19 = "fq_Lz";
export var achievementsSlider__badge_2 = "fq_Lf";
export var achievementsSlider__badge_3 = "fq_Lg";
export var achievementsSlider__badge_4 = "fq_Lh";
export var achievementsSlider__badge_5 = "fq_Lj";
export var achievementsSlider__badge_6 = "fq_Lk";
export var achievementsSlider__badge_7 = "fq_Ll";
export var achievementsSlider__badge_8 = "fq_Lm";
export var achievementsSlider__badge_9 = "fq_Ln";
export var achievementsSlider__logo = "fq_Lc";
export var achievementsSlider__swiperSliderWrapper = "fq_Lb";