// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fz_Mg";
export var succesStoriesCardRow = "fz_Mr";
export var succesStoriesCard__bottomWrapper = "fz_Mm";
export var succesStoriesCard__category = "fz_Mp";
export var succesStoriesCard__categoryWrapper = "fz_Mn";
export var succesStoriesCard__description = "fz_Ml";
export var succesStoriesCard__image = "fz_Mj";
export var succesStoriesCard__locaation = "fz_Mq";
export var succesStoriesCard__logosWrapper = "fz_Mk";
export var succesStoriesCard__title = "fz_Mh";